import { atom, useAtom } from 'jotai';

type Props = {};

type BannerState = {
  isVisible: boolean;

  messageType: 'Error' | 'Success' | 'Info';
  message: string;
};

export const bannerAtom = atom<BannerState>({
  isVisible: false,
  messageType: 'Error',
  message: 'Argus',
});

export const axiosErrorToBanner = (error: any): BannerState => ({
  isVisible: true,
  messageType: 'Error',
  message: `${error?.code || 500}: ${
    error?.message || 'Error getting challenges'
  }`,
});

const InfoBanner = (props: Props): JSX.Element => {
  const [banner, setBanner] = useAtom(bannerAtom);
  const getClasses = () => {
    switch (banner.messageType) {
      case 'Error':
        return 'bg-red-400/90';
      case 'Success':
        return 'bg-green-400/90';
      default:
        return 'bg-blue-400/90';
    }
  };

  return (
    <div className={'mt-4 mb-8 ' + (banner.isVisible ? '' : 'hidden')}>
      <div
        className={
          'w-full bg-red-400/90 py-2 px-4 rounded-xl border-white/50 border-2 ' +
          getClasses()
        }
      >
        <div className="flex items-center">
          <span>{banner.message}</span>
          <button
            className="ml-auto px-2 bg-bgDark rounded-2xl"
            onClick={() =>
              setBanner({
                ...banner,
                isVisible: false,
              })
            }
          >
            X{/* <CloseIcon className="fill-white h-1 w-1" /> */}
          </button>
        </div>
      </div>
    </div>
  );
};
export default InfoBanner;
