export interface Challenge {
  id: number;
  title?: string;
  description: string;
  showStrafeSeparately: boolean;
  gamemodes: Gamemode[];
  categories: Category[];
  difficultiesOnChallenge: DifficultyOnChallenge[];
  specialModeOnChallenge?: SpecialModeOnChallenge;
}

export interface DifficultyOnChallenge {
  difficulty: Difficulty;
  difficultyId: number;
  strafe?: string;
}

export interface Difficulty {
  id: number;
  name: string;
}

export interface Category {
  id: number;
  name: string;
}

export interface Gamemode {
  id: number;
  name: string;
}

export interface SpecialModeOnChallenge {
  specialModeId: number;
  additionalText: string;
  additionalNumber: number;
}

export interface SpecialMode {
  id: number;
  name: string;
  specialModeType: SpecialModeType;
}

export enum SpecialModeType {
  SECOND_CARD,
}

export enum EditIntention {
  ADD_CHALLENGE,
  EDIT_CHALLENGE,
}

export type AddEditChallengeForm = {
  title: string;
  description: string;
  showStrafeSeparately: boolean;
  gamemodes: Gamemode[];
  categories: Category[];
  difficultiesOnChallenge: DifficultyOnChallenge[];
  specialMode?: SpecialModeOnChallenge;
};
