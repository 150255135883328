import { Formik, FormikProps } from 'formik';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Option } from 'react-tailwindcss-select/dist/components/type';
import CustomInput from '../../../components/custom.input';
import InfoBanner, {
  axiosErrorToBanner,
  bannerAtom,
} from '../../../components/information.banner';
import NavBar from '../../../components/navbar';
import { challengeHelpers } from '../../../utils/challenge.helpers';
import { challengeService } from '../challenge.service';
import {
  AddEditChallengeForm,
  DifficultyOnChallenge,
  EditIntention,
} from '../interfaces';

type Props = {
  intent: EditIntention;
};

function ChallengeForm(props: Props) {
  const { id } = useParams();
  const [banner, setBanner] = useAtom(bannerAtom);
  const [formInitials, setFormInitials] = useState<AddEditChallengeForm>({
    title: '',
    description: '',
    showStrafeSeparately: false,
    gamemodes: [],
    categories: [],
    difficultiesOnChallenge: [],
    specialMode: null as any,
  });

  const navigate = useNavigate();

  const gamemodesRes = useQuery('gamemodes', challengeService.getGamemodes, {
    refetchOnWindowFocus: false,
  });
  const categoriesRes = useQuery('categories', challengeService.getCategories, {
    refetchOnWindowFocus: false,
  });
  const difficultiesRes = useQuery(
    'difficulties',
    challengeService.getDifficulties,
    {
      refetchOnWindowFocus: false,
    }
  );
  const specialModesRes = useQuery(
    'specialModes',
    challengeService.getSpecialModes,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (props.intent === EditIntention.EDIT_CHALLENGE && id) {
      challengeService.getChallengeById(+id).then((response) => {
        setFormInitials({
          title: response.data.title || '',
          description: response.data.description,
          showStrafeSeparately: response.data.showStrafeSeparately,
          gamemodes: response.data.gamemodes,
          categories: response.data.categories,
          difficultiesOnChallenge: response.data.difficultiesOnChallenge,
          specialMode: response.data.specialModeOnChallenge,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (
      gamemodesRes.status === 'error' ||
      categoriesRes.status === 'error' ||
      difficultiesRes.status === 'error' ||
      specialModesRes.status === 'error'
    )
      setBanner(
        axiosErrorToBanner(
          gamemodesRes.error ||
            categoriesRes.error ||
            difficultiesRes.error ||
            specialModesRes.error
        )
      );
    else setBanner({ ...banner, isVisible: false });
  }, [
    gamemodesRes.status,
    categoriesRes.status,
    difficultiesRes.status,
    specialModesRes.status,
  ]);

  const handleSubmit = async (values: AddEditChallengeForm) => {
    const result =
      props.intent === EditIntention.EDIT_CHALLENGE && id
        ? challengeService.updateChallenge(+id, values)
        : challengeService.createChallenge(values);

    result
      .then((res) => {
        navigate('/');
        setBanner({
          isVisible: true,
          messageType: 'Success',
          message: `Successfully ${
            props.intent === EditIntention.EDIT_CHALLENGE
              ? 'updated'
              : 'created'
          } challenge`,
        });
      })
      .catch((err) => {
        setBanner(axiosErrorToBanner(err));
      });
  };

  const getBody = () => {
    if (
      gamemodesRes.status === 'loading' ||
      categoriesRes.status === 'loading' ||
      difficultiesRes.status === 'loading' ||
      specialModesRes.status === 'loading'
    ) {
      return <div>Loading...</div>;
    }

    if (
      gamemodesRes.data &&
      categoriesRes.data &&
      difficultiesRes.data &&
      specialModesRes.data
    ) {
      const gamemodeOptions: Option[] = gamemodesRes.data.data.map((gm) => ({
        value: gm.id.toString(),
        label: gm.name,
      }));
      const categoryOptions: Option[] = categoriesRes.data.data.map(
        (category) => ({
          value: category.id.toString(),
          label: category.name,
        })
      );
      const difficultyOptions: Option[] = difficultiesRes.data.data.map(
        (difficulty) => ({
          value: difficulty.id.toString(),
          label: difficulty.name,
        })
      );
      const specialModeOptions: Option[] = specialModesRes.data.data.map(
        (specialMode) => ({
          value: specialMode.id.toString(),
          label: specialMode.name,
        })
      );

      return (
        <Formik
          initialValues={formInitials}
          enableReinitialize={true}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(props: FormikProps<AddEditChallengeForm>) => (
            <form onSubmit={props.handleSubmit}>
              <div className="flex justify-center">
                <div className="p-8 w-4/5 md:w-2/3 grid grid-cols-1 gap-8">
                  {/* Title input */}
                  <CustomInput
                    label={'Title'}
                    labelAddition={'optional'}
                    name={'title'}
                    value={props.values.title}
                    onChange={props.handleChange}
                    type={'text'}
                  />
                  {/* Description input */}
                  <CustomInput
                    label={'Description'}
                    labelAddition={'Eigentliche Aufgabe'}
                    name={'description'}
                    value={props.values.description}
                    onChange={props.handleChange}
                    type={'area'}
                  />
                  {/* Gamemodes input */}
                  <CustomInput
                    label={'Gamemodes'}
                    // labelAddition={'Eigentliche Aufgabe'}
                    name={'gamemodes'}
                    value={props.values.gamemodes.map((gm) => ({
                      value: gm.id.toString(),
                      label: gm.name,
                    }))}
                    onChange={(value: any) => {
                      const options = value as Option[];
                      props.setFieldValue(
                        'gamemodes',
                        options
                          ? options.map((gmOption) => ({
                              id: parseInt(gmOption.value),
                              name: gmOption.label,
                            }))
                          : []
                      );
                    }}
                    options={gamemodeOptions}
                    type={'multiselect'}
                  />
                  {/* Categories input */}
                  <CustomInput
                    label={'Categories'}
                    // labelAddition={'Eigentliche Aufgabe'}
                    name={'categories'}
                    value={props.values.categories.map((category) => ({
                      value: category.id.toString(),
                      label: category.name,
                    }))}
                    onChange={(value: any) => {
                      const options = value as Option[];
                      props.setFieldValue(
                        'categories',
                        options
                          ? options.map((categoryOption) => ({
                              id: parseInt(categoryOption.value),
                              name: categoryOption.label,
                            }))
                          : []
                      );
                    }}
                    options={categoryOptions}
                    type={'multiselect'}
                  />

                  {/* Difficulty input */}
                  <CustomInput
                    label={'Difficulties'}
                    // labelAddition={'Eigentliche Aufgabe'}
                    name={'difficulties'}
                    value={props.values.difficultiesOnChallenge.map(
                      (category) => ({
                        value: category.difficultyId.toString(),
                        label: category.difficulty.name,
                        strafe: category.strafe,
                      })
                    )}
                    onChange={(value: any) => {
                      const options = value as Option[];
                      props.setFieldValue(
                        'difficultiesOnChallenge',
                        options
                          ? options.map<DifficultyOnChallenge>(
                              (categoryOption: any) => ({
                                difficultyId: parseInt(categoryOption.value),
                                difficulty: {
                                  id: parseInt(categoryOption.value),
                                  name: categoryOption.label,
                                },
                                strafe: categoryOption.strafe,
                              })
                            )
                          : []
                      );
                    }}
                    options={difficultyOptions}
                    type={'multiselect'}
                  />
                  <div
                    key={'showStrafeSeparately'}
                    className="flex items-start"
                  >
                    <div className="w-1/5"></div>
                    <span className="text-xl font-semibold align-middle w-2/5">
                      {`Strafe separat vom Text anzeigen.`}
                    </span>
                    <input
                      className="text-md block px-3 py-2 rounded-lg"
                      name={'showStrafeSeparately'}
                      checked={props.values.showStrafeSeparately}
                      onChange={() =>
                        props.setFieldValue(
                          'showStrafeSeparately',
                          !props.values.showStrafeSeparately
                        )
                      }
                      type={'checkbox'}
                    />
                  </div>
                  {difficultiesRes.data.data.map((difficulty) => {
                    const difficultyOnChallengeFiltered = [
                      ...props.values.difficultiesOnChallenge,
                    ].filter((doc) => doc.difficultyId === difficulty.id);

                    if (difficultyOnChallengeFiltered.length !== 1) {
                      return;
                    }
                    const difficultyOnChallenge =
                      difficultyOnChallengeFiltered[0];
                    return (
                      <div
                        key={difficultyOnChallenge.difficultyId.toString()}
                        className="flex items-center"
                      >
                        <div className="w-1/5"></div>
                        <span className="text-xl font-semibold align-middle w-2/5">
                          {`Strafe '${difficultyOnChallenge.difficulty.name}' `}
                        </span>
                        <input
                          className="text-md w-2/5 block px-3 py-2 rounded-lg bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:outline-none
                                    text-black focus:border-secondaryDark"
                          name={difficultyOnChallenge.difficultyId.toString()}
                          value={difficultyOnChallenge.strafe || ''}
                          onChange={(event: any) => {
                            difficultyOnChallenge.strafe = event.target.value;
                            props.handleChange(event);
                          }}
                          type={'text'}
                        />
                      </div>
                    );
                  })}

                  {/* SpecialMode input */}
                  <CustomInput
                    label={'SpecialMode'}
                    labelAddition={'optional'}
                    name={'specialMode'}
                    value={{
                      value:
                        props.values.specialMode?.specialModeId.toString() as string,
                      label: specialModesRes.data.data.find(
                        (sm) =>
                          sm.id === props.values.specialMode?.specialModeId
                      )?.name as string,
                    }}
                    onChange={(value: any) => {
                      const option = value as Option;
                      props.setFieldValue(
                        'specialMode',
                        option && option.value
                          ? {
                              ...props.values.specialMode,
                              specialModeId: parseInt(option.value),
                            }
                          : null
                      );
                    }}
                    options={specialModeOptions}
                    type={'singleselect'}
                  />

                  {/* SpecialModeInput */}
                  {props.values.specialMode?.specialModeId ? (
                    <>
                      <CustomInput
                        type="text"
                        label="Verknüpfte Challenge"
                        name="specialMode"
                        value={props.values.specialMode.additionalText}
                        onChange={(event: any) =>
                          props.setFieldValue('specialMode', {
                            ...props.values.specialMode,
                            additionalText: event.target.value,
                          })
                        }
                      />
                      <CustomInput
                        type="text"
                        label="Anzeigen nach X Runden"
                        labelAddition="0 = direkt als nächstes"
                        name="specialMode"
                        value={props.values.specialMode.additionalNumber?.toString()}
                        onChange={(event: any) =>
                          props.setFieldValue('specialMode', {
                            ...props.values.specialMode,
                            additionalNumber: parseInt(event.target.value) || 0,
                          })
                        }
                      />
                    </>
                  ) : null}

                  {/* Save button */}
                  <div className="flex items-center">
                    <div className="w-1/5">
                      <button
                        type="submit"
                        className="bg-primaryDark mb-8 py-2 text-md font-bold px-4 rounded-lg"
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  {/* Beispiel challenge */}
                  <div className="flex mb-4">
                    <span className="w-1/5 text-xl font-semibold">
                      Example Challenge in the App
                    </span>
                    <span className="pl-4 w-4/5 text-xl">
                      {challengeHelpers.generateExampleChallenge(
                        props.values.description,
                        props.values.difficultiesOnChallenge[0]?.strafe ||
                          'STRAFE'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      );
    }
    return <div>Error.</div>;
  };
  return (
    <div className="pb-12">
      <NavBar />
      <InfoBanner />
      {getBody()}
    </div>
  );
}

export default ChallengeForm;
