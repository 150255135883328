import { atom } from 'jotai';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import ChallengeForm from './pages/challenge/addEdit/challenge';
import Challenge from './pages/challenge/challenges';
import { EditIntention } from './pages/challenge/interfaces';
import Login from './pages/login/login';
import NotFound from './pages/not.found';

const queryClient = new QueryClient();

interface AuthState {}

export const authAtom = atom<AuthState>({
  isVisible: false,
  messageType: 'Error',
  message: 'Argus',
});

function App() {
  useEffect(() => {
    document.body.classList.add('w-full', 'h-full', 'bg-bgDark', 'text-white');
    return () => {
      document.body.classList.remove(
        'w-full',
        'h-full',
        'bg-bgDark',
        'text-white'
      );
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Challenge />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/add-challenge"
          element={<ChallengeForm intent={EditIntention.ADD_CHALLENGE} />}
        />
        <Route
          path="/edit-challenge/:id"
          element={<ChallengeForm intent={EditIntention.EDIT_CHALLENGE} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
