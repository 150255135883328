import axios from 'axios';
import { apiRoutes } from '../../constants/api.constants';
import { APIError } from '../../utils/general.interfaces';
import { LoginResponse, TokenResponse } from './interfaces';

async function login(username: string, password: string) {
  try {
    const response = await axios.post<LoginResponse>(apiRoutes.login, {
      username,
      password,
    });

    if (response.status === 200 && response.data) {
      // Persist credentials in local storage
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('accessToken', response.data.accessToken);

      return response.data;
    }
  } catch (error) {
    return new APIError(420, 'Argus');
  }
}

async function refreshToken() {
  try {
    const response = await axios.post<TokenResponse>(apiRoutes.refreshToken, {
      refreshToken: localStorage.getItem('refreshToken'),
    });

    if (response.status === 200 && response.data) {
      localStorage.setItem('accessToken', response.data.accessToken);
      return response.data.accessToken;
    }
  } catch (error) {
    return null;
  }
  return null;
}

export const authService = {
  login,
  refreshToken,
};
