import axios from 'axios';
import { authService } from '../pages/login/auth.service';
const axiosTokenInstance = axios.create();

// Request interceptor for API calls
axiosTokenInstance.interceptors.request.use(
  async (config) => {
    const accessToken =
      localStorage.getItem('accessToken') || (await refreshAccessToken());

    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosTokenInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return axiosTokenInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
async function refreshAccessToken() {
  const accessToken = await authService.refreshToken();
  if (!accessToken) {
    // TODO Show error to user / redirect to login
  }
  return accessToken;
}

export default axiosTokenInstance;
