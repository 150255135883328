import React from "react";

function NotFound() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <p className="text-3xl font-bold">Not found</p>
    </div>
  );
}

export default NotFound;
