import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import InfoBanner, {
  axiosErrorToBanner,
  bannerAtom,
} from '../../components/information.banner';
import NavBar from '../../components/navbar';
import { challengeService } from './challenge.service';
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import CustomInput from '../../components/custom.input';

function Home() {
  const [banner, setBanner] = useAtom(bannerAtom);
  const [searchString, setSearchString] = useState('');

  const navigate = useNavigate();

  const { data, status, error, refetch } = useQuery(
    ['challenges', searchString],
    () => challengeService.getChallengesDetailed(searchString)
  );

  useEffect(() => {
    if (status === 'error') {
      setBanner(axiosErrorToBanner(error));
      return;
    }
    if (status === 'success' && banner.messageType === 'Error') {
      setBanner({ ...banner, isVisible: false });
      return;
    }
  }, [status]);

  const addNewChallenge = () => navigate('/add-challenge');

  const handleDelete = async (challengeId: number) => {
    challengeService
      .deleteChallenge(challengeId)
      .then((response) => {
        setBanner({
          isVisible: true,
          messageType: 'Success',
          message: `Challenge "${response.data.description.slice(
            0,
            96
          )}..." successfully deleted`,
        });
      })
      .catch((error) => {
        setBanner(axiosErrorToBanner(error));
      })
      .finally(() => {
        refetch();
      });
  };

  const getTableBody = () => {
    if (status === 'loading') {
      return <div className="text-center m-4">Loading...</div>;
    }

    if (status === 'error') {
      return <div className="text-center m-4">No data.</div>;
    }

    if (status === 'success') {
      return data.data.map((challenge, index) => (
        <div
          key={index}
          className="flex [&>*]:px-2 [&>*]:py-4 [&>*]:border [&>*]:align-middle [&>*]:border-white"
        >
          {/* Data */}
          <div className="w-1/12">
            {/* <DeleteIcon
              className="fill-white m-0 cursor-pointer h-8 w-8"
              onClick={() => handleDelete(challenge.id)}
            /> */}
            <Icon
              icon={'wpf:delete'}
              height={24}
              className="fill-white m-0 cursor-pointer"
              onClick={() => handleDelete(challenge.id)}
            />
          </div>
          <span className="w-3/12">{challenge.title}</span>
          <span className="w-2/12">
            {challenge.categories.map((c) => c.name).join(', ')}
          </span>
          <span className="w-5/12">
            {challenge.description.slice(0, 64) + '...'}
          </span>
          <span className="w-1/12 flex justify-center">
            <button
              className="bg-primaryDark py-2 px-4 rounded-lg"
              onClick={() => navigate(`/edit-challenge/${challenge.id}`)}
            >
              Edit
            </button>
          </span>
        </div>
      ));
    }

    return <div>No data.</div>;
  };

  return (
    <div>
      <NavBar />
      <div className="m-8">
        <InfoBanner />
        <button
          className="bg-primaryDark mb-8 py-2 px-4 rounded-lg"
          onClick={addNewChallenge}
        >
          Add new challenge
        </button>
        <span className="relative">
          <CustomInput
            label=""
            placeholder="Search..."
            name="search"
            value={searchString}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchString(e.target.value)
            }
            className="w-2/3 absolute right-0 top-0"
          />
        </span>
        <div className="flex border-b-2 [&>*]:px-2 [&>*]:py-4 [&>*]:border [&>*]:font-bold">
          {/* Headers */}
          <span className="w-1/12">Delete?</span>
          <span className="w-3/12">Title</span>
          <span className="w-2/12">Category</span>
          <span className="w-5/12">Description</span>
          <span className="w-1/12 text-center">Edit</span>
        </div>
        {getTableBody()}
      </div>
    </div>
  );
}

export default Home;
