import React from 'react';
import { APIError } from '../../utils/general.interfaces';
import { authService } from './auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers, FormikProps } from 'formik';

type LoginForm = {
  username: string;
  password: string;
};

function Login() {
  const formInitials: LoginForm = {
    username: '',
    password: '',
  };

  const navigate = useNavigate();

  const handleSubmit = async (
    values: LoginForm,
    helpers: FormikHelpers<LoginForm>
  ) => {
    helpers.setSubmitting(true);

    // Do async stuff
    const loginResult = await authService.login(
      values.username,
      values.password
    );
    if (loginResult instanceof APIError) {
      // Login failed
      alert('Login failed');
    } else {
      // Login succeeded
      navigate('/', { replace: true });
    }

    helpers.setSubmitting(false);
  };
  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   setSubmitting(true);
  //   console.log(username);
  //   console.log(password);
  //   // Do async stuff
  //   const loginResult = await authService.login(username, password);
  //   if (loginResult instanceof APIError) {
  //     alert('Login failed');
  //   } else {
  //     navigate('/', { replace: true });
  //   }

  //   setSubmitting(false);
  // };

  return (
    <div>
      <Link
        to={'/'}
        className="text-3xl font-bold absolute text-center right-0 left-0 py-4"
      >
        ExIt
      </Link>
      <div className="container max-w-full mx-auto py-24 px-6">
        <div className="font-sans">
          <div className="max-w-sm mx-auto px-6">
            <div className="relative flex flex-wrap">
              <div className="w-full relative">
                <div className="mt-12">
                  <div className="mb-5 pb-1border-b-2 text-center font-base text-gray-700"></div>
                  <div className="text-center font-semibold text-2xl text-white">
                    Login
                  </div>
                  <Formik initialValues={formInitials} onSubmit={handleSubmit}>
                    {(props: FormikProps<LoginForm>) => (
                      <form className="mt-4" onSubmit={props.handleSubmit}>
                        <div className="mx-auto max-w-lg">
                          <div className="py-2">
                            <span className="px-1 text-sm text-gray-600">
                              Username
                            </span>
                            <input
                              name="username"
                              value={props.values.username}
                              onChange={props.handleChange}
                              placeholder=""
                              type="text"
                              className="
                            text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:outline-none
                            text-black focus:border-secondaryDark"
                            />
                          </div>
                          <div className="py-2" x-data="{ show: true }">
                            <span className="px-1 text-sm text-gray-600">
                              Password
                            </span>
                            <div className="relative">
                              <input
                                name="password"
                                value={props.values.password}
                                onChange={props.handleChange}
                                placeholder=""
                                type="password"
                                className="
                              text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:outline-none
                              text-black focus:border-secondaryDark"
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="w-screen h-screen flex justify-center items-center flex-col">
  //     <h1 className="text-3xl text-primaryDark text-center my-8">Login</h1>
  //     <form
  //       onSubmit={handleSubmit}
  //       className="flex justify-center items-center flex-col"
  //     >
  //       <div className="grid grid-cols-2 gap-4">
  //         <label htmlFor="username">Username</label>
  //         <input
  //           className="bg-transparent border border-solid border-primaryDark rounded-md px-4 py-2"
  //           type="text"
  //           value={username}
  //           onChange={(e) => setUsername(e.target.value)}
  //         />
  //         <label htmlFor="password">Password</label>
  //         <input
  //           className="bg-transparent border border-solid border-primaryDark rounded-md px-4 py-2"
  //           type="password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //         />
  //       </div>
  //       <button type="submit" disabled={isSubmitting} className="my-4">
  //         Submit
  //       </button>
  //     </form>
  //   </div>
  // );
}

export default Login;
