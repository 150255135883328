import { apiRoutes } from '../../constants/api.constants';
import axiosTokenInstance from '../../utils/axios.interceptors';
import {
  AddEditChallengeForm,
  Category,
  Challenge,
  Difficulty,
  Gamemode,
  SpecialMode,
} from './interfaces';

async function getChallengesDetailed(searchSting?: string) {
  return axiosTokenInstance.get<Challenge[]>(apiRoutes.challengesDetailed, {
    params: {
      search: searchSting,
    },
  });
}

async function getChallengeById(id: number) {
  return axiosTokenInstance.get<Challenge>(apiRoutes.getChallengeById(id));
}

async function getGamemodes() {
  return axiosTokenInstance.get<Gamemode[]>(apiRoutes.getGamemodes);
}

async function getCategories() {
  return axiosTokenInstance.get<Category[]>(apiRoutes.getCategories);
}

async function getDifficulties() {
  return axiosTokenInstance.get<Difficulty[]>(apiRoutes.getDifficulties);
}

async function getSpecialModes() {
  return axiosTokenInstance.get<SpecialMode[]>(apiRoutes.getSpecialModes);
}

async function createChallenge(challenge: AddEditChallengeForm) {
  return axiosTokenInstance.post<Challenge>(apiRoutes.saveChallenge, {
    title: challenge.title,
    description: challenge.description,
    showStrafeSeparately: challenge.showStrafeSeparately,
    gamemodes: challenge.gamemodes.map((gm) => ({ id: gm.id })),
    categories: challenge.categories.map((category) => ({ id: category.id })),
    difficultiesOnChallenge: challenge.difficultiesOnChallenge.map((doc) => ({
      difficultyId: doc.difficultyId,
      strafe: doc.strafe,
    })),
    specialModeOnChallenge: challenge.specialMode,
  });
}

async function updateChallenge(id: number, challenge: AddEditChallengeForm) {
  return axiosTokenInstance.put<Challenge>(apiRoutes.updateChallenge(id), {
    title: challenge.title,
    description: challenge.description,
    showStrafeSeparately: challenge.showStrafeSeparately,
    gamemodes: challenge.gamemodes.map((gm) => ({ id: gm.id })),
    categories: challenge.categories.map((category) => ({ id: category.id })),
    difficultiesOnChallenge: challenge.difficultiesOnChallenge.map((doc) => ({
      difficultyId: doc.difficultyId,
      strafe: doc.strafe,
    })),
    specialModeOnChallenge: challenge.specialMode,
  });
}

async function deleteChallenge(id: number) {
  return axiosTokenInstance.delete<Challenge>(apiRoutes.deleteChallenge(id));
}

export const challengeService = {
  getChallengesDetailed,
  getGamemodes,
  getCategories,
  getDifficulties,
  getSpecialModes,
  createChallenge,
  getChallengeById,
  updateChallenge,
  deleteChallenge,
};
