const baseUrl = process.env.REACT_APP_BASE_URL;

export const apiRoutes = {
  login: baseUrl + '/login',
  refreshToken: baseUrl + '/refresh-token',
  challengesDetailed: baseUrl + '/challenges-detailed',
  getChallengeById: (id: number) => baseUrl + `/challenge/${id}`,
  saveChallenge: baseUrl + '/challenge',
  updateChallenge: (id: number) => baseUrl + `/challenge/${id}`,
  deleteChallenge: (id: number) => baseUrl + `/challenge/${id}`,
  getGamemodes: baseUrl + '/gamemodes',
  getCategories: baseUrl + '/categories',
  getDifficulties: baseUrl + '/difficulties',
  getSpecialModes: baseUrl + '/special-modes',
};
