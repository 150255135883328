import { ChangeEvent } from 'react';
import Select from 'react-tailwindcss-select';
import { Option } from 'react-tailwindcss-select/dist/components/type';

type Props = {
  label: string;
  labelAddition?: string;
  name: string;
  value: string | Option | Option[];
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  type: 'area' | 'text' | 'multiselect' | 'singleselect';
  placeholder?: string;
  options?: Option[];
  className?: string;
};

const CustomInput = (props: Props): JSX.Element => {
  const getInput = (type: string) => {
    switch (type) {
      case 'area':
        return (
          <textarea
            name={props.name}
            value={props.value as string}
            onChange={props.onChange}
            placeholder={props.placeholder || ''}
            className="text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:outline-none
                    text-black focus:border-secondaryDark"
          />
        );

      case 'multiselect':
        return (
          <Select
            value={props.value as Option[]}
            onChange={props.onChange}
            options={props.options || []}
            isMultiple={true}
          />
        );
      case 'singleselect':
        return (
          <Select
            value={props.value as Option}
            onChange={props.onChange}
            options={props.options || []}
            isMultiple={false}
            isClearable={true}
          />
        );
      default:
        return (
          <input
            name={props.name}
            value={props.value as string}
            onChange={props.onChange}
            placeholder={props.placeholder || ''}
            type="text"
            className={
              'text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:outline-none text-black focus:border-secondaryDark ' +
                props.className || ''
            }
          />
        );
    }
  };

  return (
    <div className="flex items-center">
      <div className=" w-1/5">
        <span className="text-xl font-semibold align-middle">
          {props.label}
        </span>{' '}
        {props.labelAddition ? (
          <span className="align-middle text-white/60">
            {' '}
            ({props.labelAddition})
          </span>
        ) : null}
      </div>
      <div className=" w-4/5">{getInput(props.type)}</div>
    </div>
  );
};
export default CustomInput;
