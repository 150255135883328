import react from 'react';
import { Link } from 'react-router-dom';

const NavBar = (): JSX.Element => {
  return (
    <div className="p-4 bg-bgDark-400">
      <Link
        to={'/'}
        className="text-3xl font-bold absolute text-center right-0 left-0"
      >
        ExIt
      </Link>
      <Link to={'/login'} className="relative text-2xl z-10">
        Login
      </Link>
    </div>
  );
};
export default NavBar;
