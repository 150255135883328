const playerNames = [
  'Max',
  'Noah',
  'Daniel',
  'Mini',
  'Tom',
  'Thorsten',
  'Pralle',
  'Welat',
  'Daniel',
  'Jonas',
  'Fynn',
  'Aaron',
];

const regexStrafe = /\{strafe\}/gi;
const regexPlayerA = /\{a\}/gi;
const regexPlayerB = /\{b\}/gi;

const generateExampleChallenge = (description: string, strafe: string) => {
  const firstIndex = Math.floor(Math.random() * playerNames.length);
  const playerA = playerNames[firstIndex];
  const playerB =
    playerNames[
      (Math.floor(firstIndex + Math.random() * (playerNames.length - 1)) + 1) %
        playerNames.length
    ];
  description = description
    .replace(regexPlayerA, playerA)
    .replace(regexPlayerB, playerB)
    .replace(regexStrafe, strafe);
  return description;
};

export const challengeHelpers = {
  generateExampleChallenge,
};
